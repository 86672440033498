import { useState } from 'react';
import { Chip, CircularProgress, Grid } from '@mui/material';
import { t } from 'i18next';
import moment from 'moment';
import { useQuery } from 'react-query';
import { Link, useLocation } from 'react-router-dom';

import useAxiosPrivate from 'hooks/useAxiosPrivate';
import { taskStatusTypeVariants } from 'shared/tableColVariantsList';
import TaskFilterDrawer from 'components/dashboard/TaskFilterDrawer';

export default function Columns() {
  const { search } = useLocation();
  const axiosPrivate = useAxiosPrivate();
  const [hasError, setHasError] = useState(false);
  const [expanded, setExpanded] = useState(false);

  const { data, isLoading, isFetching } = useQuery({
    queryKey: ['tasksList', search],
    queryFn: async function () {
      const response = await axiosPrivate.get(`/crm/task${search || ''}`);
      return response.data.data;
    },
    enabled: !hasError,
    onError: (error) => {
      setHasError(true);
    },
    retry: false,
  });

  return (
    <div className='component-list-wrapper bg-base-color-light-hover rounded-lg'>
      <div className='tasks-wrapper'>
        <div className='tasks-columns-wrapper'>
          {isLoading || isFetching ? (
            <div className='circular-progress-box min-h-[500px] h-full w-full'>
              <CircularProgress size={40} />
            </div>
          ) : (
            data &&
            Object.keys(data).length > 0 && (
              <Grid container spacing={3} className='tasks-columns-grid'>
                <div className='flex tasks-columns-grid'>
                  <Grid
                    item={true}
                    sm={1}
                    xs={1}
                    className='tasks-grid-column'
                    id='tasks-wrapper'
                  >
                    <div className='tasks-grid-column-title-wrapper'>
                      <div className='tasks-grid-column-title'>
                        O'tkazib yuborilgan topshiriqlar
                        <center className='text-[10px] -mb-2 text-gray-400'>
                          {(data?.left && data.left.length) || 0}
                        </center>
                      </div>
                    </div>

                    <div className='tasks-grid-column-body'>
                      <div className='h-full'>
                        {data.left &&
                          data.left.length > 0 &&
                          data.left.map((item, itemIndex) => (
                            <TaskLead
                              item={item}
                              key={`data-past-${itemIndex}`}
                            />
                          ))}
                      </div>
                    </div>
                  </Grid>
                  <Grid item={true} sm={1} xs={1} className='tasks-grid-column'>
                    <div className='tasks-grid-column-title-wrapper'>
                      <div className='tasks-grid-column-title'>
                        Bugungi topshiriqlar
                        <center className='text-[10px] -mb-2 text-gray-400'>
                          {(data?.today && data.today.length) || 0}
                        </center>
                      </div>
                    </div>

                    <div className='tasks-grid-column-body'>
                      <div className='h-full'>
                        {data.today &&
                          data.today.length > 0 &&
                          data.today.map((item, itemIndex) => (
                            <TaskLead
                              item={item}
                              key={`data-today-${itemIndex}`}
                            />
                          ))}
                      </div>
                    </div>
                  </Grid>
                  <Grid item={true} sm={1} xs={1} className='tasks-grid-column'>
                    <div className='tasks-grid-column-title-wrapper'>
                      <div className='tasks-grid-column-title'>
                        Ertangi topshiriqlar
                        <center className='text-[10px] -mb-2 text-gray-400'>
                          {(data?.tomorrow && data.tomorrow.length) || 0}
                        </center>
                      </div>
                    </div>

                    <div className='tasks-grid-column-body'>
                      <div className='h-full'>
                        {data.tomorrow &&
                          data.tomorrow.length > 0 &&
                          data.tomorrow.map((item, itemIndex) => (
                            <TaskLead
                              item={item}
                              key={`data-tomorrow-${itemIndex}`}
                            />
                          ))}
                      </div>
                    </div>
                  </Grid>
                  <Grid item={true} sm={1} xs={1} className='tasks-grid-column'>
                    <div className='tasks-grid-column-title-wrapper'>
                      <div className='tasks-grid-column-title'>
                        Kelajakdagi topshiriqlar
                        <center className='text-[10px] -mb-2 text-gray-400'>
                          {(data?.future && data.future.length) || 0}
                        </center>
                      </div>
                    </div>

                    <div className='tasks-grid-column-body'>
                      <div className='h-full'>
                        {data.future &&
                          data.future.length > 0 &&
                          data.future.map((item, itemIndex) => (
                            <TaskLead
                              item={item}
                              key={`data-future-${itemIndex}`}
                            />
                          ))}
                      </div>
                    </div>
                  </Grid>
                </div>
              </Grid>
            )
          )}
        </div>
      </div>
      {!hasError && (
        <TaskFilterDrawer
          open={expanded}
          setOpen={setExpanded}
          // filterData={filterData}
          // isLoading={isFilterLoading}
        />
      )}
    </div>
  );
}

const TaskLead = ({ item }) => {
  const setOrderStatus = (item) => {
    let result = taskStatusTypeVariants.filter(
      (variant) => variant.code == item
    );
    if (result.length > 0) {
      return (
        <Chip
          label={t(result[0].label)}
          variant='tableBadgeMini'
          color={result[0].color}
          sx={{
            height: '20px',
            fontSize: '0.65rem',
            borderRadius: '6px',
            '& .MuiChip-label': {
              padding: '0 4px',
            },
          }}
        />
      );
    }
    return '';
  };
  return (
    <Link
      to={`/admin/crm/lead/edit/${item?.crm_lead_id}#task-${item?.id}`}
      className='task-card no-underline'
    >
      <div className='flex items-center justify-between'>
        <span className='card-date text-end text-[12px]'>
          {item?.crm_lead?.name}
        </span>
        <span className='card-date text-end text-[10px] text-gray-500'>
          {moment(item?.date, 'YYYY-MM-DD HH:mm').format('DD.MM.YYYY HH:mm')}
        </span>
      </div>
      <div className='card-title text-[12px] text-gray-500'>{item?.title}</div>
      <div className='flex items-center justify-between'>
        <span className='card-date text-end text-[12px] text-blue-600'>
          {item?.crm_lead?.contactone?.phonone?.phone}
        </span>
        <span className='card-date text-end text-[12px]'>
          {setOrderStatus(item?.status)}
        </span>
      </div>
    </Link>
  );
};
