import { Fragment } from 'react';
import Shaxmatka2Row from './Shaxmatka2Row';
import HOME_TYPE from 'shared/homeTypeList';

const Shaxmatka2PadezdItem = ({
  block,
  padezdItem,
  padezdIndex,
  blockIndex,
  toggleSelectionItem = () => {},
  settingsMenuValue = '',
  isEditing,
}) => {
  const getRoomsByStatus = (statusCode) => {
    return padezdItem?.homes.length > 0
      ? padezdItem?.homes.filter((item) => item.status == statusCode).length
      : 0;
  };

  const getFullFloorsNumberByPadezd = (padezd) => {
    let a = Array.from(new Set(padezd.homes.map((item) => item.stage)));
    if (a.includes('-1')) {
      a.push('0');
    }
    a.sort((x, y) => x - y);
    return a.reverse();
  };

  return (
    <Fragment>
      <div className={`my-2 mt-0 ml-1 leading-4${!padezdIndex && ' ml-9'}`}>
        <span className='font-medium text-base text-line-1'>
          {padezdItem?.name}
        </span>
        <div className='text-[12px] leading-3 flex items-center'>
          <div className='flex items-center'>
            <div className='w-[14px] h-[14px] rounded-sm mr-1 bg-base-color-disabled'></div>
            {getRoomsByStatus(HOME_TYPE.ACTIVE.code)}
          </div>
          <span className='mx-1 text-gray-500'>|</span>
          <div className='flex items-center'>
            <div className='w-[14px] h-[14px] rounded-sm mr-1 bg-orange-400'></div>
            {getRoomsByStatus(HOME_TYPE.TIME.code)}
          </div>
          <span className='mx-1 text-gray-500'>|</span>
          <div className='flex items-center'>
            <div className='w-[14px] h-[14px] rounded-sm mr-1 bg-red-500'></div>
            {getRoomsByStatus(HOME_TYPE.ORDERED.code)}
          </div>
          <span className='mx-1 text-gray-500'>|</span>
          <div className='flex items-center'>
            <div className='w-[14px] h-[14px] rounded-sm mr-1 bg-gray-400'></div>
            {getRoomsByStatus(HOME_TYPE.DISABLED.code)}
          </div>
        </div>
      </div>
      {getFullFloorsNumberByPadezd(padezdItem).map((floorNumber, index) => (
        <div
          className={`sheet-row ${
            padezdIndex != 0 ? '!pl-0' : ''
          } floor-${floorNumber}`}
          key={`block-${padezdItem?.id}-row-${index}`}
          id={`block-${padezdItem?.id}-row-${index}`}
        >
          <Shaxmatka2Row
            block={block}
            homesData={padezdItem?.homes}
            blockIndex={blockIndex}
            padezdIndex={padezdIndex}
            floorNumber={floorNumber}
            isEditing={isEditing}
            size={new Set(padezdItem?.homes.map((item) => item.stage)).size}
            toggleSelectionItem={toggleSelectionItem}
            settingsMenuValue={settingsMenuValue}
          />
        </div>
      ))}
    </Fragment>
  );
};

export default Shaxmatka2PadezdItem;
