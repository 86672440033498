import { CircularProgress } from '@mui/material';
import { memo, useRef } from 'react';
import { useDraggable } from 'react-use-draggable-scroll';
import Shaxmatka2BlockItem from './block/Shaxmatka2BlockItem';

export default memo(function Shaxmatka1({
  blocksQuery,
  objectQuery,
  blocks,
  toggleSelectionItem,
  settingsMenuValue,
  isEditing = false,
}) {
  const ref = useRef(); // We will use React useRef hook to reference the wrapping div:
  const { events } = useDraggable(ref);
  return (
    <div className='sheet-wrapper type-2'>
      <div className='sheet-base-area'>
        <div className='sheet-grid gap-2' {...events} ref={ref}>
          {(blocksQuery.isLoading || blocksQuery.isFetching) &&
          (objectQuery.isLoading || objectQuery.isFetching) ? (
            <div className='circular-progress-box min-h-[500px] h-full w-full'>
              <CircularProgress size={50} />
            </div>
          ) : (
            blocksQuery.data &&
            blocksQuery.data.length > 0 &&
            blocks &&
            blocks.map(
              (block, index) =>
                block?.padezd?.length > 0 && (
                  <div
                    className='sheet-column flex gap-0'
                    key={`block-${block?.id}-columns`}
                    id={`block-${block?.id}-columns`}
                  >
                    <Shaxmatka2BlockItem
                      blockItem={block}
                      blockIndex={index}
                      isEditing={isEditing}
                      toggleSelectionItem={toggleSelectionItem}
                      settingsMenuValue={settingsMenuValue}
                    />
                  </div>
                )
            )
          )}
        </div>
      </div>
    </div>
  );
});
