import React from 'react';
import CRMTaskMessage from '../../crm/crm-parts/card/card-add-edit/message-types/CRMTaskMessage';
import { Dialog, IconButton } from '@mui/material';

export default function TaskCompleteModal({
  data,
  setData,
  open,
  refetchFn = () => {},
}) {
  const onClose = () => setData(null);
  return (
    <Dialog open={open} onClose={onClose}>
      <div className='flex justify-end pt-1 pr-1'>
        <IconButton variant='onlyIcon' color='primary' onClick={onClose}>
          <i className='bi bi-x' />
        </IconButton>
      </div>
      <div className='py-4 px-8 min-w-[600px]'>
        <CRMTaskMessage data={data} refetchFn={refetchFn} />
      </div>
    </Dialog>
  );
}
